import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';
import { jwtDecode } from 'jwt-decode';
import { MsAuthPlugin } from '@recognizebv/capacitor-plugin-msauth';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  sparoScopes = ['api://d42b7693-51f2-4bd8-a279-cff5f00db724/access_as_user'];
  constructor(
    private router: Router,
    public platform: Platform,
    private msalService: MsalService
  ) {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.msalService.instance.initialize().then(() => {
        this.msalService.instance
          .handleRedirectPromise()
          .then((response: AuthenticationResult | null) => {
            if (response) {
              Preferences.set({
                key: 'accessToken',
                value: response.accessToken,
              });
              this.router.navigate(['/']);
              this.msalService.instance.setActiveAccount(response.account);
            }
          });
      });
    }
  }

  async login(): Promise<string | null> {
    if ( (this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      return await this.loginNative();
    } else {
      return await this.loginWeb();
    }
  }

  logout(params?: string) {
    if ( (this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      this.logoutNative(params);
    } else {
      this.logoutWeb(params);
    }
  }

  async loginNative(): Promise<string | null> {
    try {
      var response = await MsAuthPlugin.login({
        clientId: 'd42b7693-51f2-4bd8-a279-cff5f00db724',
        tenant: 'organizations',
        scopes: this.sparoScopes,
        keyHash: 'VzSiQcXRmi2kyjzcA+mYLEtbGVs=',
      });
      Preferences.set({
        key: 'accessToken',
        value: response.accessToken,
      });
      // if (this.router.url.includes("login"))
      //   this.router.navigate(['/']);
      return response.accessToken;
    } catch (error) {
      console.error('Login error:', error);
    }
  }

  async loginWeb(): Promise<string | null> {
    const loginRequest = {
      scopes: this.sparoScopes,
      redirectUri: window.location.origin,
    };
    try {
      var response = await this.msalService.instance.acquireTokenSilent({
        scopes: this.sparoScopes,
        account: this.msalService.instance.getAllAccounts()[0],
      });
      await Preferences.set({
        key: 'accessToken',
        value: response.accessToken,
      });
      this.msalService.instance.setActiveAccount(response.account);
      return response.accessToken;
    } catch (ex) {
      console.log(ex);
      this.msalService.loginRedirect(loginRequest);
    }
  }

  logoutNative(params?: string) {
    MsAuthPlugin.logout({
      clientId: 'd42b7693-51f2-4bd8-a279-cff5f00db724',
      tenant: 'organizations',
      keyHash: 'VzSiQcXRmi2kyjzcA+mYLEtbGVs=',
    })
      .then(() => {
        Preferences.remove({ key: 'accessToken' });
        this.navigateToLogin(params);
      })
      .catch((error) => {
        Preferences.remove({ key: 'accessToken' });
        this.navigateToLogin(params);
      });
  }

  logoutWeb(params) {
    Preferences.remove({ key: 'accessToken' });
    this.msalService.instance.clearCache();
    this.msalService.instance.setActiveAccount(null);
    this.navigateToLogin(params);
  }

  public async fetchValidToken(): Promise<string | null> {
    const { value: accessToken } = await Preferences.get({
      key: 'accessToken',
    });
    if (!accessToken) return;
    if (accessToken && !this.isTokenExpired(accessToken)) {
      return accessToken;
    }
    return await this.refreshTokenIfNeeded();
  }

  private isTokenExpired(token: string): boolean {
    // return true;
    const decoded: any = jwtDecode(token);
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return decoded.exp < currentTime;
  }

  private async refreshTokenIfNeeded(): Promise<string | null> {
    try {
      return await this.login();
    } catch (error) {
      let params = 'invalidRefreshToken=true';
      this.logout(params);
    }
    return null;
  }

  private navigateToLogin(params?: string) {
    if (params) {
      this.router.navigateByUrl('/login?' + params);
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
