import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, IonItemSliding, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import {
  AttendeeInformation,
  CalendarEvent,
} from 'src/app/interfaces/interfaces';
import { AllPeopleService } from 'src/app/services/all-people-service/all-people.service';
import { ApiService } from 'src/app/services/api-services/api.service';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { checkIsNextDay } from 'src/app/utils/date-time-utils';
import * as moment from 'moment';
import { StringInfoMessageService } from 'src/app/services/string-info-message/string-info-message.service';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';
import { PlacesService } from 'src/app/services/placesService/places.service';

@Component({
  selector: 'app-my-bookings-item',
  templateUrl: './my-bookings-item.component.html',
  styleUrls: ['./my-bookings-item.component.scss'],
})
export class MyBookingsItemComponent implements OnInit, OnDestroy {
  @Input() calendarEvent: any;
  @Input() bookings$;
  @Input() loggedInUser;
  @Input() selectedSegment;
  @Input() pendingUserResponeCount;

  @ViewChild('popover') popover;
  @ViewChild('slidingItem') slidingItem: IonItemSliding;
  @ViewChild('SeriesPopOver') SeriesPopOver;
  @ViewChild('ResponsePopOver') ResponsePopOver;
  @ViewChild('userResponseTooltipPopover') userResponseTooltipPopover;
  public locale;
  public isNextDay = false;
  acceptedAttendees: AttendeeInformation[] = [];
  declinedAttendees: AttendeeInformation[] = [];
  pendingAttendees: AttendeeInformation[] = [];
  tentativelyAttendees: AttendeeInformation[] = [];
  isPopoverOpen: boolean = false;
  isSeriesPopoverOpen: boolean = false;
  isResponsePopoverOpen: boolean = false;
  isLoading: boolean = false;
  myResponseToEvent: string;
  isSeries: boolean = false;
  userResponseTooltipPopoverOpen: boolean = false;
  noAddressSpecified: boolean = false;
  address : string = ""
  constructor(
    public platform : Platform,
    private languageService: LanguageService,
    private stringInfoMessageService: StringInfoMessageService,
    private router: Router,
    public actionSheetController: ActionSheetController,
    private translateService: TranslateService,
    private apiService: ApiService,
    private allPeopleService: AllPeopleService,
    private cgf: ChangeDetectorRef,
    private dateTimeFormatService: DatetimeFormatService,
    private placeService:PlacesService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit() {    
    this.address = this.stringInfoMessageService.buildAddressString(this.calendarEvent.place)
    if (this.selectedSegment == 'invitations') {
      let meAsAnAttendee = this.calendarEvent.attendees.find((attendee) => {
        if (attendee) {
          return (
            attendee.userPrincipalName == this.loggedInUser?.userPrincipalName
          );
        }
      });
      this.myResponseToEvent = meAsAnAttendee?.response;
    }
    this.isNextDay = checkIsNextDay(
      this.calendarEvent.startTime,
      this.calendarEvent.endTime
    );
    this.allPeopleService.personsListLoading$.subscribe((loading) => {
      if (!loading) {
        this.calendarEvent.attendees =
          this.allPeopleService.extendAttendeeInformation(
            this.calendarEvent.attendees
          );
        this.splitAttendessByResponse();
      }
    });
    this.isSeries = this.calendarEvent.seriesMasterId != null ? true : false;

    this.dateTimeFormatService.selectedDateTimeFormat$.subscribe((format) => {
      this.locale = format;
    });
  }

  async acceptEvent(eventId, isSeriesAction = false) {
    this.slidingItem.closeOpened()
    this.isResponsePopoverOpen = false
    const actionSheet = await this.actionSheetController.create({
      header: isSeriesAction
        ? this.translateService.instant('acceptSeriesMeetingQuestion')
        : this.translateService.instant('acceptMeetingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('feedback.answers.yes'),
          role: 'destructive',
        },
        {
          text: this.translateService.instant('feedback.answers.no'),
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.isLoading = true;
      if (isSeriesAction)
        this.changeSeriesActionIsLoading(
          this.calendarEvent?.seriesMasterId,
          true
        );
      this.apiService.InvitationRespond(eventId, 'Accept').subscribe((res) => {
        if (res) {
          this.isLoading = false;
          if (isSeriesAction) {
            this.changeSeriesActionIsLoading(
              this.calendarEvent?.seriesMasterId,
              false
            );
            this.changeActionInvetationResponse(
              this.calendarEvent?.seriesMasterId,
              'Accepted'
            );
          } else {
            this.changeActionInvetationResponse(
              this.calendarEvent?.id,
              'Accepted'
            );
          }
          this.cgf.detectChanges();
        }
      });
    }
  }

  async declineEvent(eventId, isSeriesAction = false) {
    this.isResponsePopoverOpen = false
    this.slidingItem.closeOpened()
    const actionSheet = await this.actionSheetController.create({
      header: isSeriesAction
        ? this.translateService.instant('declineSeriesMeetingQuestion')
        : this.translateService.instant('declineMeetingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('feedback.answers.yes'),
          role: 'destructive',
        },
        {
          text: this.translateService.instant('feedback.answers.no'),
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.isLoading = true;
      if (isSeriesAction)
        this.changeSeriesActionIsLoading(
          this.calendarEvent?.seriesMasterId,
          true
        );
      this.apiService.InvitationRespond(eventId, 'Decline').subscribe((res) => {
        if (res) {
          this.isLoading = false;
          if (isSeriesAction) {
            this.changeSeriesActionIsLoading(
              this.calendarEvent?.seriesMasterId,
              false
            );
            this.changeActionInvetationResponse(
              this.calendarEvent?.seriesMasterId,
              'Declined'
            );
          } else {
            this.changeActionInvetationResponse(
              this.calendarEvent?.id,
              'Declined'
            );
          }
          this.cgf.detectChanges();
        }
      });
    }
  }

  async tentativelyAcceptEvent(eventId, isSeriesAction = false) {
    this.isResponsePopoverOpen = false
    this.slidingItem.closeOpened()
    const actionSheet = await this.actionSheetController.create({
      header: isSeriesAction
        ? this.translateService.instant(
            'tentativelyAcceptSeriesMeetingQuestion'
          )
        : this.translateService.instant('tentativelyAcceptMeetingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('feedback.answers.yes'),
          role: 'destructive',
        },
        {
          text: this.translateService.instant('feedback.answers.no'),
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.isLoading = true;
      if (isSeriesAction)
        this.changeSeriesActionIsLoading(
          this.calendarEvent?.seriesMasterId,
          true
        );
      this.apiService
        .InvitationRespond(eventId, 'TentativelyAccept')
        .subscribe((res) => {
          if (res) {
          this.isLoading = false;
            if (isSeriesAction) {
              this.changeSeriesActionIsLoading(
                this.calendarEvent?.seriesMasterId,
                false
              );
              this.changeActionInvetationResponse(
                this.calendarEvent?.seriesMasterId,
                'TentativelyAccepted'
              );
            } else {
              this.changeActionInvetationResponse(
                this.calendarEvent?.id,
                'TentativelyAccepted'
              );
            }
            this.cgf.detectChanges();
          }
        });
    }
  }

  editBooking(booking) {
    let type = EVENTPAGETYPES.editBooking;
    booking = JSON.stringify(booking);
    const nav: NavigationExtras = {
      queryParams: { type, booking },
      replaceUrl: true,
    };
    this.router.navigate(['event'], nav);
  }

  async doDeleteBooking(id: string, isSeriesAction: boolean = false) {
    const actionSheet = await this.actionSheetController.create({
      header: isSeriesAction
        ? this.translateService.instant('deleteSeriesBookingQuestion')
        : this.translateService.instant('deleteBookingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: isSeriesAction
            ? this.translateService.instant('deleteSeriesBooking')
            : this.translateService.instant('deleteBooking'),
          role: 'destructive',
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.isLoading = true;
      if (isSeriesAction) {
        this.changeSeriesActionIsLoading(
          this.calendarEvent?.seriesMasterId,
          true
        );
      }
      this.deleteEvent(id).subscribe((isDeleted) => {
        if (isDeleted) {
          if(isSeriesAction){
            this.placeService.deleteSeriesEventFromMyBooking(id);
          }
          else{
            this.placeService.deleteEventFromMyBooking(id);
          }
        }
      });
    }
  }

  private deleteEvent(id: string) {
    return this.apiService.deleteCalendarEvent(id).pipe(
      map((isDeleted) => isDeleted),
      take(1)
    );
  }

  splitAttendessByResponse() {
    this.acceptedAttendees = []
    this.pendingAttendees = []
    this.declinedAttendees = []
    this.tentativelyAttendees = []
    this.calendarEvent.attendees.forEach((attendee) => {
      switch (attendee.response.toLowerCase()) {
        case 'accepted':
          this.acceptedAttendees.push(attendee);
          break;
        case 'declined':
          this.declinedAttendees.push(attendee);
          break;
        case 'none':
        case 'notresponded':
          if (
            attendee.userPrincipalName !=
            this.calendarEvent.organizer.emailAddress
          ) {
            this.pendingAttendees.push(attendee);
          }
          break;
        case 'tentativelyaccepted':
          this.tentativelyAttendees.push(attendee);
          break;
        default:
          break;
      }
    });
  }
  presentOrganizerPopover(e: Event) {
    this.userResponseTooltipPopover.event = e
    this.userResponseTooltipPopoverOpen = true


  }
  presentPopover(e: Event) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }
  presentSeriesPopOver(e: Event) {
    this.SeriesPopOver.event = e;
    this.isSeriesPopoverOpen = true;
  }
  presentResponsePopOver(e: Event) {
    this.ResponsePopOver.event = e;
    this.isResponsePopoverOpen = true;
  }

  triggerIonIcon(id) {
    let root = document.getElementById(id);
    root.click();
  }

  buildSeriesInformation(recurrencePattern) {
    return this.stringInfoMessageService.seriesEventInformationMessage(
      recurrencePattern,true
    );
  }

  async chooseIfSeriesToast(type) {
    this.isResponsePopoverOpen = false;
    this.slidingItem.closeOpened()
    let actionSheetButtons = [
      {
        text: this.translateService.instant('series.EventOccurrence'),
        role: 'occurrence',
      },
      {
        text: this.translateService.instant('series.EventSeries'),
        role: 'series',
      },
      {
        text: this.translateService.instant('cancelButton'),
        role: 'cancel',
      },
    ];

    let actionSheetHeader = this.translateService.instant(
      'series.' + type + 'SeriesEventHeaderModal'
    );

    const actionSheet = await this.actionSheetController.create({
      header: actionSheetHeader,
      mode: 'ios',
      buttons: actionSheetButtons,
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    this.chooseMatchingAction(type, condition.role);
  }
  chooseMatchingAction(type, role) {
    switch (type) {
      case 'delete':
        if (role === 'occurrence') {
          this.doDeleteBooking(this.calendarEvent.id, false);
        }
        if (role === 'series') {
          this.doDeleteBooking(this.calendarEvent.seriesMasterId, true);
        }
        break;
      case 'accept':
        if (role === 'occurrence') {
          this.acceptEvent(this.calendarEvent.id, false);
        }
        if (role === 'series') {
          this.acceptEvent(this.calendarEvent.seriesMasterId, true);
        }
        break;
      case 'tentativelyAccept':
        if (role === 'occurrence') {
          this.tentativelyAcceptEvent(this.calendarEvent.id, false);
        }
        if (role === 'series') {
          this.tentativelyAcceptEvent(this.calendarEvent.seriesMasterId, true);
        }
        break;
      case 'decline':
        if (role === 'occurrence') {
          this.declineEvent(this.calendarEvent.id, false);
        }
        if (role === 'series') {
          this.declineEvent(this.calendarEvent.seriesMasterId, true);
        }
        break;
      case 'edit':
        if (role === 'occurrence') {
          this.editBooking(this.calendarEvent);
        }
        if (role === 'series') {
          this.calendarEvent.editMasterEvent = true;
          this.editBooking(this.calendarEvent);
        }
        break;
      default:
        break;
    }
  }

  changeSeriesActionIsLoading(seriesMasterId, isLoading) {
    this.bookings$.subscribe((events: any) => {
      events.forEach((event) => {
        if (event.seriesMasterId === seriesMasterId) {
          event.isSeriesActionLoding = isLoading;
        }
      });
    });
  }
  changeActionInvetationResponse(id, Response) {
    this.bookings$.subscribe((events: any) => {
      events.forEach((event) => {
        if (event.seriesMasterId === id || event.id === id) {
          event.attendees.forEach((attendee) => {
            if (
              attendee.userPrincipalName == this.loggedInUser?.userPrincipalName
            ) {
              attendee.response = Response;
            }
          });
        }
      });
    });
  }
  getUserResponse() {
    let meAsAnAttendee = this.calendarEvent.attendees.find((attendee) => {
      if (attendee) {
        return (
          attendee.userPrincipalName == this.loggedInUser?.userPrincipalName
        );
      }
    });
    return meAsAnAttendee?.response;
  }
  joinMeeting(url: string) {
    window.open(url, '_blank');
  }
}
