/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import {
  AttendeeInformation,
  EventRequest,
  FeedbackInterface,
  FloorPlanInfo,
  Place,
  PlaceAvatar,
  SearchRequest,
  UserInformation,
  UserPreferences,
  UserSettings,
} from 'src/app/interfaces/interfaces';
import { CalendarEvent } from 'src/app/interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { FeedbackService } from '../feedback-service/feedback.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  userInformation$ = new BehaviorSubject<UserInformation>(null);
  userPreferences$ = new BehaviorSubject<UserPreferences>(null);
  userInvitationEventsCount$ = new BehaviorSubject<number>(null);
  placesAvatars$ = new BehaviorSubject<PlaceAvatar[]>(null);
  LogInLoading = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private injector: Injector) {}

  getPlaceById(id: string): Observable<Place> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<Place>(environment.baseUrl + `/api/places/${id}`, {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getAvailablePlaces(): Observable<any> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<Place[]>(environment.baseUrl + '/api/places', { headers: header })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  checkPlaceAvailability(
    email: string,
    startTime: string,
    endTime: string,
    isOnPrem: boolean,
    oldStartTime = '',
    oldEndTime = '',
    oldsubject = '',
    timeZoneId = ''
  ): Observable<boolean> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<boolean>(
        environment.baseUrl +
          '/api/places/' +
          email +
          '/available?startTime=' +
          startTime +
          '&endTime=' +
          endTime +
          '&isOnPrem=' +
          isOnPrem +
          '&oldStartTime=' +
          oldStartTime +
          '&oldEndTime=' +
          oldEndTime +
          '&oldsubject=' +
          oldsubject +
          '&timeZoneId' +
          timeZoneId,
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  
  checkOrgTeamsConnector() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<boolean>(environment.baseUrl + '/api/connector/canBookOnline', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  checkPlaceAvailabilityAndTillEndOfDay(
    email: string,
    endOfTheDay: string,
    startTime: string,
    endTime: string,
    isOnPrem: boolean,
    oldStartTime = '',
    oldEndTime = '',
    oldsubject = '',
    timeZoneId = ''
  ): Observable<boolean> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<boolean>(
        environment.baseUrl +
          '/api/places/' +
          email +
          '/availableAndTillTheEnd?endOfTheDay=' +
          endOfTheDay +
          '&startTime=' +
          startTime +
          '&endTime=' +
          endTime +
          '&isOnPrem=' +
          isOnPrem +
          '&oldStartTime=' +
          oldStartTime +
          '&oldEndTime=' +
          oldEndTime +
          '&oldsubject=' +
          oldsubject +
          '&timeZoneId=' +
          timeZoneId,
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  searchAvailablePlaces(searchRequest: SearchRequest) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<Place[]>(
        environment.baseUrl +
          '/api/places/search?startTime=' +
          searchRequest.startTimeUTC +
          '&endTime=' +
          searchRequest.endTimeUTC +
          '&placesType=' +
          searchRequest.type,
        { headers: header }
      )
      .pipe(
        map((r) => r),
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getMyCalendarEvents() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<CalendarEvent[]>(environment.baseUrl + '/api/me/events', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  InvitationRespond(eventId, userResponse: string) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<CalendarEvent[]>(
        environment.baseUrl + '/api/me/event/' + eventId + '/' + userResponse,
        {},
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  createCalendarEvent(eventRequest: EventRequest): Observable<any> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<any>(environment.baseUrl + '/api/me/event', eventRequest, {
        headers: header,
        observe: 'response',
      })
      .pipe(
        tap((response) => {
          if (response.status == 201) {
            this.injector.get(FeedbackService).addBookingCount();
          }
        }),
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  createCalendarEventNew(eventRequest: EventRequest, isOnPrem: Boolean) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<any>(
        environment.baseUrl + '/api/me/CreateEventNew?isOnPrem=' + isOnPrem,
        eventRequest,
        { headers: header, observe: 'response' }
      )
      .pipe(
        map((response) => {
          console.log(response);
          if (response.status == 201) {
            this.injector.get(FeedbackService).addBookingCount();
          }
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  editCalendarEvent(id: string, placeEvent: EventRequest) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .put<EventRequest>(
        environment.baseUrl + '/api/me/event/' + id,
        placeEvent,
        { headers: header }
      )
      .pipe(
        map((placesEvent) => placesEvent),
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  deleteCalendarEvent(id: string) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .delete<boolean>(environment.baseUrl + '/api/me/event/' + id, {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getUserInformation() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<UserInformation>(environment.baseUrl + '/api/me', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  sendUserFeedback(feedback: any) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<FeedbackInterface>(
        environment.baseUrl + '/api/feedback',
        feedback,
        { headers: header, observe: 'response' }
      )
      .pipe(
        tap((response) => {
          if (response.status == 201) {
            this.injector.get(FeedbackService).stopReminder();
          }
        }),
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getAllAttendeesList() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<AttendeeInformation[]>(environment.baseUrl + '/api/attendees', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  checkAttendeeAvailability(
    upnList: string[],
    startTime: string,
    endTime: string,
    oldStartTime = '',
    oldEndTime = ''
  ): Observable<{ [key: string]: boolean }> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<{ [key: string]: boolean }>(
        environment.baseUrl +
          '/api/attendees' +
          '/available?startTime=' +
          startTime +
          '&endTime=' +
          endTime +
          '&oldStartTime=' +
          oldStartTime +
          '&oldEndTime=' +
          oldEndTime +
          '&oldsubject=',
        upnList,
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  sendContactForm(form: any) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<any>(environment.baseUrl + '/api/account/contact/send', form, {
        headers: header,
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  sendProblemTicket(form: any) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<any>(environment.baseUrl + '/api/support/problem/send', form, {
        headers: header,
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  sendIdeaTicket(form: any) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<any>(environment.baseUrl + '/api/support/idea/send', form, {
        headers: header,
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getPlaceScheduleById(id: string, start, end): Observable<any> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<Place>(
        environment.baseUrl +
          `/api/places/${id}/availabletimes?start=${start}&end=${end}`,
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  ToggleFavoritePlace(placeUPN: string) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .post<any>(
        environment.baseUrl + `/api/places/${placeUPN}/favorite/toggle`,
        {},
        { headers: header, observe: 'response' }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getFavortePlaces() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<any>(environment.baseUrl + '/api/places/favorite', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getAllPlacesEmailAdress() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<String[]>(environment.baseUrl + '/api/places/allPlacesEmailAdress', {
        headers: header,
      })
      .pipe(
        (res) => res,
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  searchFavoriteAvailablePlaces(searchRequest: SearchRequest) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<Place[]>(
        environment.baseUrl +
          '/api/places/search/favorite?startTime=' +
          searchRequest.startTimeUTC +
          '&endTime=' +
          searchRequest.endTimeUTC +
          '&placesType=' +
          searchRequest.type,
        { headers: header }
      )
      .pipe(
        map((r) => r),
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getNotFavoritePlaces() {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<any>(environment.baseUrl + '/api/places/notfavorite', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getFloorPlanInfo(): Observable<FloorPlanInfo[]> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<any>(environment.baseUrl + '/api/floorplan/info', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getFloorPlanSchedule(
    places: string[],
    forceRefresh: boolean = false
  ): Observable<Place[]> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );

    return this.http
      .post<any>(
        environment.baseUrl + '/api/places/available/' + forceRefresh,
        places,
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getFloorPlanImage(id: string): Observable<string> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<any>(environment.baseUrl + '/api/floorplan/' + id + '/image', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getUserPreferences(): Observable<UserPreferences> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<UserPreferences>(environment.baseUrl + '/api/userSettings', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  updateUserSettings(userSettings: UserSettings) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .put(environment.baseUrl + '/api/userSettings/settings', userSettings, {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  toggleMapFavorite(mapId: string) {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .put(
        environment.baseUrl + '/api/userSettings/favorite-maps/' + mapId,
        {},
        { headers: header }
      )
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  getInvitationEventsCount(): Observable<number> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<number>(environment.baseUrl + '/api/Me/invitaionEventsCount', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getAvatarsForPlaces(): Observable<PlaceAvatar[]> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<any>(environment.baseUrl + '/api/places/avatars', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getActionGroupMembers(): Observable<any[]> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    return this.http
      .get<any>(environment.baseUrl + '/api/Groups/safetygroupsinfo', {
        headers: header,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
}
