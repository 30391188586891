import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';
import { Place } from 'src/app/interfaces/interfaces';
import { PlacesSettingsService } from 'src/app/services/places-settings-service/places-settings.service';
import { PlacesService } from 'src/app/services/placesService/places.service';

const localStorageSettingsKey = "placesSettings"
const localStoragePlaceTagKey = "placeTag";

@Component({
  selector: 'app-places-settings',
  templateUrl: './places-settings.component.html',
  styleUrls: ['./places-settings.component.scss'],
})
export class PlacesSettingsComponent  implements OnInit {
  countries = []
  cities = []
  streets = []
  floorLabels = []
  floorNumbers = []
  locations = {

  }
  unfilteredPlacesSubscribtion: Subscription;

  saveLocation: any;
  unfilteredPlaces: any;
  constructor(
    private translateService : TranslateService,
    private placesService : PlacesService,
    public placeSettingsService : PlacesSettingsService,
  ) { 
    this.saveLocation = this.placeSettingsService.checkIfSettingsIsCached()
  }

  ngOnInit() {
    this.unfilteredPlacesSubscribtion = this.placesService.unfilteredPlaces$.subscribe(places => {
      this.InitLocalVariables(places);
      this.unfilteredPlaces = places;
    });
  }
  ngOnDestroy(){
    this.unfilteredPlacesSubscribtion.unsubscribe();
  }

  private InitLocalVariables(places: Place[]) {
    places.forEach(place => {
      // Collect unique countries, cities, streets, floors, and floor numbers
      if (place.address?.country 
        && !this.countries?.includes(place.address.country)) {
        this.countries.push(place.address.country);
      }

      if (place.address?.city 
        && !this.cities?.includes(city => city == place.address.city)) {
        this.cities.push(place.address.city);
      }

      if (place.address?.street 
        && !this.streets?.includes(place.address.street)) {
        this.streets.push(place.address.street);
      }

      if (place.address?.floor 
        && !this.floorLabels?.includes(place.address.floor)) {
        this.floorLabels.push(place.address.floor);
      }

      if (place.address?.floorNumber 
        && !this.floorNumbers.includes(place.address.floorNumber)) {
        this.floorNumbers.push(place.address.floorNumber);
      }

      // Destructuring the address for convenience
      const { country, city, street, floor: floorLabel, floorNumber } = place.address || {};

      if (country) {
        // Ensure country exists in locations
        if (!this.locations[country]) {
          this.locations[country] = { value: country, cities: {} };
        }

        if (city) {
          // Ensure city exists under country
          if (!this.locations[country].cities[city]) {
            this.locations[country].cities[city] = { value: city, streets: {} };
          }

          if (street) {
            // Ensure street exists under city
            if (!this.locations[country].cities[city].streets[street]) {
              this.locations[country].cities[city].streets[street] = { value: street, floorLabels: {} };
            }

            if (floorLabel) {
              // Ensure floorLabel exists under street
              if (!this.locations[country].cities[city].streets[street].floorLabels[floorLabel]) {
                this.locations[country].cities[city].streets[street].floorLabels[floorLabel] = { value: floorLabel, floorNumbers: [] };
              }

              if (floorNumber != null) {
                // Ensure floorNumbers array exists and push the floorNumber if not already present
                if (!this.locations[country].cities[city].streets[street].floorLabels[floorLabel].floorNumbers.includes(floorNumber)) {
                  this.locations[country].cities[city].streets[street].floorLabels[floorLabel].floorNumbers.push(floorNumber);
                }
              }
            }
          }
        }
      }
    });

    if (this.placeSettingsService.checkIfFilterIsApplied()) {
      let { country, city, street, floor: floorLabel } = this.placeSettingsService.filterCriteria;
      
      if (this.placeSettingsService.filterCriteria.country) {
        this.cities = Object.keys(this.locations[country].cities);
      }
      if (this.placeSettingsService.filterCriteria.city) {
        this.streets = Object.keys(this.locations[country].cities[city].streets);
      }
      if (this.placeSettingsService.filterCriteria.street) {
        this.floorLabels = Object.keys(this.locations[country].cities[city].streets[street].floorLabels);
      }
      if (this.placeSettingsService.filterCriteria.floor) {
        this.floorNumbers = this.locations[country].cities[city].streets[street].floorLabels[floorLabel].floorNumbers;
      }
    }
  }

  handleFilterChange(event) {
    this.updateLocalVariables(event)
    this.placeSettingsService.updateFilterCriteria(this.saveLocation,event)
  }
  
  updateLocalVariables(event) {
    let {country,city,street} = this.placeSettingsService.filterCriteria
    switch (event.target.name){
      case "country" : 
        this.cities = Object.keys(this.locations[event.target.value].cities)
        this.streets = []
        this.floorLabels = []
        this.floorNumbers = []
        this.placeSettingsService.filterCriteria.city = ""
        this.placeSettingsService.filterCriteria.street = ""
        this.placeSettingsService.filterCriteria.floor = ""
        this.placeSettingsService.filterCriteria.floorNumber = ""
        break;
      case "city" : 
        this.streets = Object.keys(this.locations[country].cities[event.target.value].streets)
        this.floorLabels = []
        this.floorNumbers = []
        this.placeSettingsService.filterCriteria.street = ""
        this.placeSettingsService.filterCriteria.floor = ""
        this.placeSettingsService.filterCriteria.floorNumber = ""
        break;
      case "street" : 
        this.floorLabels = Object.keys(this.locations[country].cities[city].streets[event.target.value].floorLabels)
        this.floorNumbers = []
        this.placeSettingsService.filterCriteria.floor = ""
        this.placeSettingsService.filterCriteria.floorNumber = ""
        break;
      case "floor" : 
        this.floorNumbers = this.locations[country].cities[city].streets[street].floorLabels[event.target.value].floorNumbers
        this.placeSettingsService.filterCriteria.floor = ""
        break;


    }
  }
  resetFilterCriteria() {
    this.saveLocation = false;
    this.placeSettingsService.updateFilterCriteria(this.saveLocation);
    this.placeSettingsService.removeFilterCriteriaFromLocalStorage();
  }

  handleSaveLocationChange(event) {
    if(event.target.checked) {
      this.saveLocation = true
      this.placeSettingsService.saveFilterCriteriaToLocalStorage();
    } else {
      this.saveLocation = false
      this.placeSettingsService.removeFilterCriteriaFromLocalStorage()
    }
    
  }
  changeTag(event) {
    const tag = event.detail.value;
    this.placeSettingsService.placeTag$.next(tag);

    if(this.placeSettingsService.isFilterCriteriaCached){
      localStorage.setItem(localStoragePlaceTagKey,JSON.stringify(tag))
    }
  }
  checkIfThereIsTag(tag: string) {
    if (this.unfilteredPlaces) {
      return this.unfilteredPlaces.find(
        (place) => place.tag == tag ? true : false
      );
    }
    return false;
  }

}
