import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlacesService } from '../placesService/places.service';

const localStorageSettingsKey = "placesSettings"
const localStoragePlaceTagKey = "placeTag"
@Injectable({
  providedIn: 'root'
})
export class PlacesSettingsService {
  filterCriteria = {
    country:"",
    city:"",
    street:"",
    floor:"",
    floorNumber:""
  }
  isFilterCriteriaCached: boolean = false
  placeTag$: BehaviorSubject<string> = new BehaviorSubject<string>("");


  constructor(
    private placesService:PlacesService
  ) {
    this.checkIfSettingsIsCached()
   }

   updateFilterCriteria(saveLocation,event?) {
    if(!event) {
      this.filterCriteria = {
        country:"",
        city:"",
        street:"",
        floor:"",
        floorNumber:"",
      }
      this.placeTag$.next("");
    } else {
      this.filterCriteria[event.target.name] = event.target.value
    }
    let filteredPlaces = this.placesService.filterPlacesBasedOnSettingsCriteria(this.placesService.unfilteredPlaces$.value,this.filterCriteria);
    this.placesService.places$.next(filteredPlaces)
    if(saveLocation) {
      this.saveFilterCriteriaToLocalStorage()
    }

   }

   saveFilterCriteriaToLocalStorage() {
    localStorage.setItem(localStorageSettingsKey,JSON.stringify(this.filterCriteria))
    localStorage.setItem(localStoragePlaceTagKey,JSON.stringify(this.placeTag$.value))
    this.isFilterCriteriaCached = true;
   }

   removeFilterCriteriaFromLocalStorage() {
    let localStorageFilterCriteria = localStorage.getItem(localStorageSettingsKey)
    if (localStorageFilterCriteria) {
      localStorage.removeItem(localStorageSettingsKey);
      localStorage.removeItem(localStoragePlaceTagKey);
    }
    this.isFilterCriteriaCached = false;
   }

   checkIfSettingsIsCached() {
    let localStorageFilterCriteria = localStorage.getItem(localStorageSettingsKey)
    let localStoragePlaceTag = localStorage.getItem(localStoragePlaceTagKey);
    if(localStorageFilterCriteria) {
      this.filterCriteria = JSON.parse(localStorageFilterCriteria)
      this.placeTag$.next(JSON.parse(localStoragePlaceTag));
      return this.isFilterCriteriaCached = true
    } else {
      return this.isFilterCriteriaCached = false

    }
   }
   checkIfFilterIsApplied() {
    if(this.placeTag$.value != '') return true
      return Object.keys(this.filterCriteria).some(key => {
    // Check if the value of the current key is truthy
    return !!this.filterCriteria[key] ;
  });
   }
}
